<template>
  <div class="debit-card">
    <div class="debit-card__header">
      <asset-img v-if="mps && mps.image" :src="mps.image" class="debit-card__mps" />
      <img v-else :src="defaultImg" class="debit-card__mps" />
      <div class="debit-card__header-end">
        <main-label :color="item.status.color" class="debit-card__label l-hidden-ms-down">
          {{ item.status.msg }}
        </main-label>
        <div class="debit-card__tools">
          <v-popover
            v-click-outside="closePopoverOnOutside"
            placement="left"
            :open="isPopoverOpen"
            trigger="manual"
            popover-class="debit-card__popover"
          >
            <plain-button icon="more" class="debit-card__more" @click="togglePopoverState" />
            <base-context-menu
              slot="popover"
              :menu="contextMenu"
              :tools="tools"
              :module-main="moduleMain"
              :tariff="item"
              class="debit-card__context-menu"
            />
          </v-popover>
        </div>
      </div>
    </div>
    <main-label :color="item.status.color" class="debit-card__label l-hidden-sm-up">
      {{ item.status.msg }}
    </main-label>
    <div class="debit-card__info">
      <div v-if="item.customname" class="debit-card__customname medium-title">
        {{ item.customname }}
      </div>
      <div class="debit-card__name standart-text note-color">
        {{ nameFormatted }}
      </div>
    </div>
  </div>
</template>

<script>
import AssetImg from '@/components/AssetImg/AssetImg.vue';
import { BillingPaymentMethod } from '@/models/BillMgr/Billing';
import BaseContextMenu from '@/components/BaseContextMenu/BaseContextMenu.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import contextMenu from '../../mixins/debitcardContextMenu';
import popoverFix from '@/mixins/popoverFix';
import { IspTools } from '@/models/base/IspTools';
import { getBin, getMps, getFormattedCardNumber } from '@/utils/paymentCard';
export default {
  components: {
    AssetImg,
    BaseContextMenu,
    MainLabel,
  },
  mixins: [contextMenu, popoverFix],
  props: {
    item: {
      type: BillingPaymentMethod,
      required: true,
      validator: val => val instanceof BillingPaymentMethod,
    },
    moduleMain: {
      type: String,
      required: true,
    },
    tools: {
      type: IspTools,
      required: true,
      validator: tools => tools instanceof IspTools,
    },
  },
  computed: {
    current() {
      return this.item;
    },
    bin() {
      return getBin(this.item.name);
    },
    mps() {
      return getMps(this.bin);
    },
    nameFormatted() {
      return getFormattedCardNumber(this.item.name);
    },
    defaultImg() {
      return this.$store.state.moduleApp.host + this.item.image;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.debit-card {
  flexy(space-between, stretch, nowrap, column);
  min-height: 10rem;
  background: var(--debit-card-bg);
  border: 1px solid var(--main-card-border);
  border-radius: $border-radius-small;
  color: var(--main-card-color);
  transition: background $anim-base-duration ease, box-shadow $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease;
  padding: 1.5rem;

  &__header {
    flexy(space-between, center);

    &-end {
      flexy(flex-end, center);
      margin: -0.25rem;
      margin-left: auto;
    }
  }

  &__info {
    margin-top: 2rem;
  }

  &__mps {
    display: block;
    size(auto, 1.25rem);
  }

  &__customname {
    margin-bottom: 0.25rem;
  }

  &__label {
    margin-top: 0.75rem;
    flex: 0 0 auto;
    align-self: flex-start;

    +breakpoint(sm-and-up) {
      margin: 0.25rem;
      align-self: center;
    }
  }

  &__tools {
    margin: 0.25rem;
  }
}
</style>
