<template>
  <transition name="fade" mode="out-in">
    <base-loader v-if="isLoading" />
    <main-card v-else>
      <div class="debit-card">{{ $t('text') }}</div>
      <div class="l-flex-1_sm-2_md-3 gutter-24 debit-card-container">
        <!--        <div v-for="item in list" :key="item.name" class="l-col">-->
        <div v-for="item in list" :key="item.recurring" class="l-col">
          <debit-card :item="item" :module-main="mainModule" :tools="tools" />
        </div>
        <div v-if="toolNew" class="l-col">
          <div class="debit-card-add" @click="addCard">
            {{ $t('add') }}
          </div>
        </div>
      </div>
    </main-card>
  </transition>
</template>

<script>
import DebitCard from './DebitCard.vue';
import wizardPay from '@/mixins/billmgr/wizardPay';
import Vue from 'vue';
import Link from '@/components/Configurator/components/Link';
import redirectLink from '@/mixins/redirectLink';
export default {
  name: 'DebitCardContainer',
  components: {
    DebitCard,
  },
  mixins: [wizardPay, redirectLink],
  data() {
    return {
      startParams: {},
      paymethod: '',
      mainModule: 'moduleBilling.modulePayment',
    };
  },
  computed: {
    moduleState() {
      const splitted = this.mainModule.split('.');
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path;
    },
    providerCurrent() {
      return this.$store.getters['moduleProviders/provider'];
    },
    // paymethod() {
    //   return this.providerCurrent === '3' ? '46' : '47'; //важно!
    // },
    modulePath() {
      return this.mainModule.replaceAll('.', '/');
    },
    list() {
      return this.$store.getters[`${this.modulePath}/cardList`];
      // return this.$store.getters[`${this.modulePath}/GET_SORTED_LIST`];
    },
    isLoading() {
      return this.moduleState.isLoading;
    },
    tools() {
      return this.moduleState.tools;
    },
    toolNew() {
      return this.tools.new;
    },
    appHost() {
      return this.$store.state.moduleApp.host;
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      this.$store.dispatch('moduleBilling/modulePayment/fetchList');
    },
    addCard() {
      this.startFunc = 'payment.stored_methods.add';
      Object.assign(this.startParams, {
        paymethod: this.providerCurrent === '3' ? '47' : '49',
        skipNext: true,
      });
      this.runWizardPay().then(data => {
        if (data && data.ok && data.ok.type === 'blank' && data.ok.v) {
          let redirectLink = window.open();
          this.$store.dispatch('moduleBilling/modulePayment/updateList');
          let token = localStorage.getItem('token');
          const link = this.appHost + data.ok.v + `&auth=${token}`;

          if (redirectLink) redirectLink.location.href = link;
          else {
            this.$modals.close();
            this.showModal(link);
          }
        }
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "add": "Добавить карту +",
    "text": "Сохраненные банковские карты и электронные кошельки позволяют совершать покупки в один клик, а также их можно использовать для автопродления услуг и автопополнения баланса лицевого счета."
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.debit-card {
  margin-bottom: 1.5rem
}
.debit-card-container {
  margin-bottom: (-1 * $gutter-vertical-ms);

  +breakpoint(md-and-up) {
    margin-bottom: (-1 * $gutter-vertical);
  }
}
.debit-card-add {
  flexy(center, center);
  position: relative;
  //height: 100%;
  min-height: 10rem;
  background: var(--debit-card-bg);
  border: 1px solid var(--main-card-border);
  border-radius: $border-radius-small;
  color: $primary-color;
  transition: background $anim-base-duration ease, box-shadow $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease;
  padding: 1.5rem;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    size(100%);
    absolute(0px, 0px);
    transition: opacity 0.3s;
    opacity: 0;
    background: var(--debit-card-bg-hover);
    border-radius: inherit;
  }

  &:hover {
    &::before {
      opacity: 0.2;
    }
  }
}
</style>
