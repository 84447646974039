<template>
  <div class="deposit">
    <base-loader v-if="loading" />
    <div v-else>
      <page-block :title="this.$t('balance.title')" class="deposit__block deposit__block--add">
        <payment-add class="deposit__balance-card" />
      </page-block>
      <!--        v-if="isView && !loading && provider"-->
      <page-block
        v-if="isView"
        :title="this.$t('cards.title')"
        class="deposit__block deposit__block--cards"
      >
        <debit-card-container />
      </page-block>
    </div>
  </div>
</template>

<script>
import DebitCardContainer from '../components/DebitCardsContainer/DebitCardContainer.vue';
import PaymentAdd from '../components/PaymentAdd.vue';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'Deposit',
  components: {
    DebitCardContainer,
    PaymentAdd,
  },
  mixins: [showErrorModal],
  data() {
    return {
      loading: true,
      viewAddCard: true,
    };
  },
  computed: {
    access() {
      return this.$store.state.moduleProfile.access;
    },
    typeOrg() {
      return this.$store.state.moduleProfile.payers.type;
    },
    provider() {
      return this.$store.state.moduleProviders.current === '3';
    },
    isView() {
      return this.$store.getters['moduleBilling/modulePayment/isViewCardList'];
      // return this.provider ? this.typeOrg === '1' : true;
    },
  },
  watch: {
    viewAddCard: {
      handler: function (event) {
        // console.log(event);
        // event && event === true ? (this.viewAddCard = true) : null;
      },
      immediate: true,
    },
    provider: {
      handler: function (event) {
        // console.log(event);
      },
      immediate: true,
    },
    typeOrg: {
      handler: function (event) {
        // console.log(event);
      },
      immediate: true,
    },
    // loading(event) {
    //   console.log(event);
    // },
  },
  beforeRouteLeave(_from, _to, next) {
    this.viewAddCard = false;
    next();
  },
  mounted() {
    if (this.isView) {
      this.loading = false;
    } else if (this.access['customer.profile']) {
      this.$store
        .dispatch('moduleBilling/modulePayers/fetchList')
        .then(data => {
          const type = data && data.elem && data.elem[0].profiletype_orig === '1';
          this.$store.dispatch('moduleBilling/modulePayment/getViewCardList', type);
        })
        .then(() => (this.loading = false))
        .catch(e => this.showError(e));
    } else {
      this.viewAddCard = this.provider ? this.typeOrg === '1' : true;
      this.$store.dispatch('moduleBilling/modulePayment/getViewCardList', this.viewAddCard);
      this.loading = false;
    }
  },
};
</script>

<i18n>
{
  "ru": {
    "balance": {
      "title": "Пополнить баланс",
      "label": "Сумма",
      "add": "Пополнить"
    },
    "cards": {
      "title": "Привязанные карты"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.deposit {
  &__balance-card {
    max-width: 468px;
  }

  &__block {
    &--cards {
      max-width: 966px;
    }
  }
}
</style>
